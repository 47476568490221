import { useEffect, useRef, useState } from 'react'
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT, CellUnits } from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'

const LeavesCalendar = ({ myHours, setValueDate, valueDate }) => {
  const divElementRef = useRef(null)

  ViewTypes.Custom = 99

  const startDate = moment().subtract(1, 'months').date(26).format(DATE_FORMAT)

  const customBehaviors = {
    getCustomDateFunc: (schedulerData, num, date) => {
      let currentDate = date ? moment(date, DATE_FORMAT) : moment(schedulerData.startDate, DATE_FORMAT)

      if (!currentDate.isValid()) {
        currentDate = moment()
      }

      currentDate.add(num, 'months')

      const newStartDate = currentDate.clone().date(26)
      const newEndDate = currentDate.clone().add(1, 'months').date(25)

      return {
        startDate: newStartDate.format(DATE_FORMAT),
        endDate: newEndDate.format(DATE_FORMAT),
        cellUnit: CellUnits.Day
      }
    }
  }

  const [viewModel, setViewModel] = useState(() => {
    const initialWidth = divElementRef.current?.offsetWidth || window.innerWidth
    const schedulerData = new SchedulerData(startDate, ViewTypes.Custom, false, false, {}, customBehaviors)
    schedulerData.config.creatable = false
    schedulerData.config.dnd = false
    schedulerData.config.views = []
    schedulerData.config.showAgenda = false
    schedulerData.config.showMonth = true
    schedulerData.config.showWeek = false
    schedulerData.config.schedulerWidth = initialWidth - 350
    return schedulerData
  })

  const fetchDataForDateRange = () => {
    const myHoursWithDate = myHours.map((h) => {
      const [day, month, year] = h.date.split('/').map(Number)
      h.dateObj = new Date(year, month - 1, day)
      return h
    })

    myHoursWithDate.sort((a, b) => a.dateObj - b.dateObj)

    const events = []
    let i = 0
    while (i < myHoursWithDate.length) {
      const h = myHoursWithDate[i]
      if (h.isLeaveDay) {
        const startDate = h.dateObj
        let endDate = h.dateObj

        while (
          i + 1 < myHoursWithDate.length &&
          myHoursWithDate[i + 1].isLeaveDay &&
          myHoursWithDate[i + 1].dateObj - myHoursWithDate[i].dateObj === 24 * 60 * 60 * 1000 &&
          myHoursWithDate[i + 1].leaveCategory === myHoursWithDate[i].leaveCategory
        ) {
          i++
          endDate = myHoursWithDate[i].dateObj
        }

        events.push({
          id: startDate.toISOString(),
          showPopover: false,
          start: startDate,
          end: new Date(endDate.getTime() + 24 * 60 * 60 * 1000),
          resourceId: '12345',
          title: `${h.leaveCategory}`,
          bgColor: '#00BEA5',
          status: 'Approved',
          movable: false,
          resizable: false,
          type: '12345'
        })

        i++
      } else {
        const hours = parseFloat(h.hours)
        if (Number.isFinite(hours)) {
          events.push({
            id: h.date,
            showPopover: false,
            start: h.dateObj,
            end: new Date(h.dateObj.getTime() + 24 * 60 * 60 * 1000),
            resourceId: '12345',
            title: `${h.hours == 0 ? '' : parseFloat(h.hours).toFixed(1)}`,
            bgColor: `${h.hours == 0 ? '#FB4141' : '#686D7690'}`,
            status: 'Approved',
            movable: false,
            resizable: false,
            type: '12345'
          })
        }
        i++
      }
    }

    const resources = [{ id: '12345', name: 'Oret' }]

    return { events, resources }
  }

  useEffect(() => {
    const { events, resources } = fetchDataForDateRange()
    setViewModel((prevSchedulerData) => {
      const newSchedulerData = new SchedulerData(
        prevSchedulerData.startDate,
        prevSchedulerData.viewType,
        prevSchedulerData.showAgenda,
        prevSchedulerData.isEventPerspective,
        { ...prevSchedulerData.config },
        prevSchedulerData.behaviors
      )
      newSchedulerData.setResources(resources)
      newSchedulerData.setEvents(events || [])
      return newSchedulerData
    })
  }, [viewModel.startDate, myHours, valueDate])

  useEffect(() => {
    const handleResize = () => {
      setViewModel((prevSchedulerData) => {
        const contentWrapper = document.getElementById('wages-container')?.offsetWidth || window.innerWidth
        const clientWidth = divElementRef.current?.offsetWidth || contentWrapper

        const newSchedulerWidth = clientWidth ? clientWidth - 80 : contentWrapper - 120

        if (prevSchedulerData.config.schedulerWidth === newSchedulerWidth) {
          return prevSchedulerData
        }

        const newSchedulerData = new SchedulerData(
          prevSchedulerData.startDate,
          prevSchedulerData.viewType,
          prevSchedulerData.showAgenda,
          prevSchedulerData.isEventPerspective,
          { ...prevSchedulerData.config, schedulerWidth: newSchedulerWidth },
          prevSchedulerData.behaviors
        )
        newSchedulerData.setResources(prevSchedulerData.resources)
        newSchedulerData.setEvents(prevSchedulerData.events)
        return newSchedulerData
      })
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const scrollCalendar = (left = false) => {
    const view = document.getElementsByClassName('scheduler-view')
    const scrollView = view[0]?.querySelectorAll(':scope > div')
    if (scrollView[1]) scrollView[1].scrollLeft = left ? 1 : 0
    setTimeout(() => {
      if (scrollView[1]) scrollView[1].scrollLeft = left ? 0 : 5
    }, 300)
  }

  const prevClick = (schedulerData) => {
    const { startDate } = schedulerData.behaviors.getCustomDateFunc(schedulerData, -1, schedulerData.startDate)
    const { endDate } = schedulerData.behaviors.getCustomDateFunc(schedulerData, -2, schedulerData.endDate)
    setValueDate(endDate)
    const newSchedulerData = new SchedulerData(
      startDate,
      schedulerData.viewType,
      schedulerData.showAgenda,
      schedulerData.isEventPerspective,
      { ...schedulerData.config },
      schedulerData.behaviors
    )
    const { events, resources } = fetchDataForDateRange()
    newSchedulerData.setResources(resources)
    newSchedulerData.setEvents(events || [])
    setViewModel(newSchedulerData)

    scrollCalendar(false)
  }

  const nextClick = (schedulerData) => {
    const { startDate } = schedulerData.behaviors.getCustomDateFunc(schedulerData, 1, schedulerData.startDate)
    const { endDate } = schedulerData.behaviors.getCustomDateFunc(schedulerData, 0, schedulerData.endDate)
    setValueDate(endDate)
    const newSchedulerData = new SchedulerData(
      startDate,
      schedulerData.viewType,
      schedulerData.showAgenda,
      schedulerData.isEventPerspective,
      { ...schedulerData.config },
      schedulerData.behaviors
    )
    const { events, resources } = fetchDataForDateRange()
    newSchedulerData.setResources(resources)
    newSchedulerData.setEvents(events || [])
    setViewModel(newSchedulerData)

    scrollCalendar(true)
  }

  return (
    <div ref={divElementRef} style={{ width: '101%', overflow: 'hidden' }}>
      <Scheduler schedulerData={viewModel} prevClick={prevClick} nextClick={nextClick} />
    </div>
  )
}

export default LeavesCalendar
