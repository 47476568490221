export const leavesTableOptions = (props) => ({
  download: false,
  print: false,
  search: false,
  sort: false,
  filter: true,
  viewColumns: true,
  pagination: true,
  serverSide: true,
  selectableRows: 'none',
  responsive: 'scroll',
  filterType: 'dropdown',
  count: props?.state?.dataTableTotal,
  rowsPerPage: props?.state?.dataTableRowsPerPage,
  rowsPerPageOptions: [10, 20, 50, 100],
  page: props?.state?.page,
  elevation: 0,
  textLabels: {
    body: {
      noMatch: 'Nuk ka të dhëna'
    }
  },
  onTableChange: props?.state?.selectedUserID ? null : props?.onTableChange
})

export const leavesTableSelectedUserColumns = (props) => [
  { name: 'Tipi i pushimit' },
  { name: 'Prej', options: { filter: false } },
  { name: 'Deri', options: { filter: false } },
  { name: 'Data e kërkesës', options: { display: 'false', filter: false } },
  { name: 'Ditë pune', options: { filter: false } },
  { name: 'Shqyrtuesi i kërkesës', options: { display: 'false', filter: false } },
  {
    name: 'Statusi',
    options: {
      filter: true,
      filterList: props.filterList['status'],
      filterOptions: {
        names: Object.keys(props.filterOptions['status'] || {})
      }
    }
  },
  { name: 'Raporti', options: { filter: false } },
  { name: 'Modifiko', options: { filter: false } }
]

export const leavesTableColumns = (props) => [
  { name: 'Përdoruesi', options: { filter: false } },
  { name: 'Email', options: { display: props.showEmail, filter: false } },
  {
    name: 'Gjuhët',
    options: {
      display: props.showLanguage,
      filterList: props.filterList['languages'],
      filterOptions: {
        names: Object.keys(props.filterOptions['languages'] || {})
      }
    }
  },
  {
    name: 'Tipi i pushimit',
    options: {
      filterList: props.filterList['leavecategories'],
      filterOptions: {
        names: Object.keys(props.filterOptions['leavecategories'] || {})
      }
    }
  },
  { name: 'Prej', options: { filter: false } },
  { name: 'Deri', options: { filter: false } },
  { name: 'Data e kërkesës', options: { display: props.showCreatedDate, filter: false } },
  { name: 'Ditë pune', options: { filter: false } },
  {
    name: 'Statusi',
    options: {
      filterList: props.filterList['status'],
      filterOptions: {
        names: Object.keys(props.filterOptions['status'] || {})
      }
    }
  },
  {
    name: 'Lokacioni i punës',
    options: {
      display: props.showLocation,
      filterList: props.filterList['office_locations'],
      filterOptions: {
        names: Object.keys(props.filterOptions['office_locations'] || {})
      }
    }
  },
  {
    name: 'Aktiviteti',
    options: {
      display: props.showActivity,
      filterList: props.filterList['activities'],
      filterOptions: {
        names: Object.keys(props.filterOptions['activities'] || {})
      }
    }
  },
  {
    name: 'Pozita',
    options: {
      display: props.showPozita,
      filterList: props.filterList['roles'],
      filterOptions: {
        names: Object.keys(props.filterOptions['roles'] || {})
      }
    }
  },
  {
    name: 'Projekti',
    options: {
      display: props.showProject,
      filterList: props.filterList['projects'],
      filterOptions: {
        names: Object.keys(props.filterOptions['projects'] || {})
      }
    }
  },
  {
    name: 'Departamenti',
    options: {
      display: props.showDepartment,
      filterType: 'checkbox',
      filterList: props.filterList['departments'],
      filterOptions: {
        names: Object.keys(props.filterOptions['departments'] || {})
      }
    }
  },
  { name: 'Raporti', options: { filter: false } },
  { name: 'Modifiko', options: { filter: false } }
]
