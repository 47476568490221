import { Box, Button, Chip, Tooltip } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { downloadLeaveReport } from './downloadLeaveReport'

export const returnTheName = (position) => `${position?.first_name || ''} ${position?.last_name || ''}`

export const returnTheBadge = (leave) => {
  const statusColorMap = {
    Approved: 'success',
    Rejected: 'error',
    Pending: 'warning'
  }

  const approvals = [
    leave?.hr_approval && `Aprovuar nga HR: ${returnTheName(leave?.hr_approval)}`,
    leave?.manager_approval && `Aprovuar nga Manager: ${returnTheName(leave?.manager_approval)}`,
    leave?.teamleader_approval && `Aprovuar nga Team Leader: ${returnTheName(leave?.teamleader_approval)}`
  ]
    .filter(Boolean)
    .join('\n')

  return (
    <Tooltip
      title={(leave.status === 'Approved' || leave.status === 'Partially approved') && approvals}
      componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
    >
      <Chip label={leave?.status} color={statusColorMap[leave?.status] || 'primary'} />
    </Tooltip>
  )
}

export const leavesTableSelectedUserData = (oneLeave) => [
  oneLeave?.category?.name,
  moment(oneLeave?.date_from).format('DD/MM/YYYY'),
  moment(oneLeave?.date_to).format('DD/MM/YYYY'),
  moment(oneLeave?.created_date).format('DD/MM/YYYY'),
  oneLeave?.days,
  returnTheName(oneLeave?.hr_approval || oneLeave?.manager_approval || oneLeave?.teamleader_approval),
  returnTheBadge(oneLeave),
  <Button onClick={() => downloadLeaveReport(oneLeave)} disabled={oneLeave?.status !== 'Approved'} size='small' variant='outlined'>
    Shkarko
  </Button>,
  <Link to={`/manageLeaves/${oneLeave?.user_id._id}/${oneLeave?._id}`}>
    <Button size='small' variant='outlined'>
      {oneLeave?.status === 'Pending' || oneLeave?.status === 'Partially approved' ? ' Shqyrto' : 'Modifiko'}
    </Button>
  </Link>
]

export const leavesTableData = (oneLeave) => [
  <Box sx={{ display: 'flex' }}>
    <Link
      to={{
        pathname: `/manageLeaves/${oneLeave?.user_id?._id}`,
        state: {
          userName: oneLeave?.user_id?.first_name,
          lastName: oneLeave?.user_id?.last_name
        }
      }}
    >
      {returnTheName(oneLeave?.user_id)}
    </Link>
    {moment().diff(oneLeave?.user_id?.first_call, 'months') < 6 && (
      <Chip label='I RI' size='small' color='primary' variant='outlined' sx={{ marginLeft: '5px' }} />
    )}
  </Box>,
  oneLeave?.user_id?.email,
  oneLeave?.user_id?.languages?.map((item) => item?._id?.code).join(', '),
  oneLeave?.category?.name,
  moment(oneLeave?.date_from).format('DD/MM/YYYY'),
  moment(oneLeave?.date_to).format('DD/MM/YYYY'),
  moment(oneLeave?.created_date).format('DD/MM/YYYY'),
  oneLeave?.days,
  returnTheBadge(oneLeave),
  oneLeave?.user_id?.office_location,
  oneLeave?.user_id?.activity?.name,
  oneLeave?.user_id?.function?.name,
  oneLeave?.user_id?.project?.name,
  oneLeave?.user_id?.department?.map((item) => item?.name).join(', '),
  <Button onClick={() => downloadLeaveReport(oneLeave)} disabled={oneLeave?.status !== 'Approved'} size='small' variant='outlined'>
    Shkarko
  </Button>,
  <Link
    to={{
      pathname: `/manageLeaves/${oneLeave?.user_id?._id}/${oneLeave?._id}`,
      state: {
        status: oneLeave?.status === 'Pending' || oneLeave?.status === 'Partially approved' ? ' Shqyrto' : 'Modifiko'
      }
    }}
  >
    <Button size='small' variant='outlined'>
      {oneLeave?.status === 'Pending' || oneLeave?.status === 'Partially approved' ? ' Shqyrto' : 'Modifiko'}
    </Button>
  </Link>
]
