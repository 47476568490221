import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import downloadCSV from './../CSV/csv'

const useTableOptions = (tableParams, setTableParams, from, to, searchText) => {
  return {
    elevation: 0,
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    responsive: 'scroll',
    download: false,
    pagination: true,
    serverSide: true,
    count: tableParams.total,
    rowsPerPage: 10,
    viewColumns: false,
    rowsPerPageOptions: [10, 50, 100, 200],
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setTableParams((prevState) => ({ ...prevState, limit: e }))
      setTableParams((prevState) => ({ ...prevState, page: 0 }))
    },
    onChangePage: (e) => {
      setTableParams((prevState) => ({ ...prevState, page: e }))
    },
    customToolbar: () => (
      <Tooltip title={'Shkarko CSV'}>
        <IconButton
          aria-label={'Shkarko CSV'}
          onClick={() => {
            downloadCSV(from, to, searchText)
          }}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    )
  }
}

export default useTableOptions
