const buildQueryString = (from, to, searchText) => {
  let query = ''

  if (from.isChecked) {
    query += `&fromDate=${from.value}`
  }

  if (to.isChecked) {
    query += `&toDate=${to.value}`
  }

  if (searchText) {
    query += `&search=${searchText}`
  }

  return query
}

export default buildQueryString
